<template>
	<v-row>
		<v-col cols="12">
			<v-row>
                <v-col md="7">
					<h3>EMPLEADOS</h3>
					<span>{{ filtered_employees.length }} en total</span>	
				</v-col>
                <v-col md="5">
					<v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search" class="rounded-lg" outlined dense single-line hide-details></v-text-field>
                </v-col>

			</v-row>
			<v-row>
				<v-col v-if="is_admin" md="3" class="d-flex align-start">
					<v-select v-model="area" :items="areas" label="Area" @change="filter_change" class="rounded-lg" outlined dense hide-details></v-select>
				</v-col>
				<v-col class="d-flex align-start justify-end">
					<v-btn dark depressed @click="$root.$emit('employee-details', { create: true })">
						Crear empelado
						<v-icon class="ml-2">mdi-account-multiple-plus</v-icon>
					</v-btn>
					<download-csv :name="generate_file_name('csv')" :data="filtered_employees" :labels="labels">
						<v-btn class="ml-3" depressed>Exportar a excel<v-icon class="ml-2" small>mdi-download</v-icon></v-btn>
					</download-csv>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-data-table :headers="headers" :items="filtered_employees" :search="search" dense hide-default-footer :items-per-page="-1">
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon @click="$root.$emit('employee-details', { create: false, employee: item })"><v-icon small>mdi-pencil</v-icon></v-btn>
				</template>
			</v-data-table>
		</v-col>
		<employee-details></employee-details>
	</v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { get_current_date } from '@/assets/modules'

import EmployeeDetails from '@/components/EmployeeDetails'

export default {
	name: 'Admin',
	components: { EmployeeDetails },
	data: () => ({
		card_id: '',
        search:  '',
		area: localStorage.getItem('ponches-area') || 'Todas',
		areas: ['Todas', 'Amelia', 'Ramon', 'Rufina'],
		labels: { name: 'PERSONNAME', employee_number: 'PERSONNUM', area: 'AREA', card_id: 'CARDID' },

		headers: [
			{ text: 'Nombre', align: 'start', sortable: true, value: 'name', },
			{ text: 'No. Empleado', align: 'start', sortable: true, value: 'employee_number', },
			{ text: 'Area', value: 'area', },
			{ text: 'Id tarjeta', align: 'start', sortable: true, value: 'card_id', },
            { text: '', value: 'actions', align: 'end' }
		],

		loading: false,
        rules: [ v => !!v || 'Campo es requerido' ],
	}),
	methods: {
		get_current_date,
		filter_change() { localStorage.setItem('ponches-area', this.area) },
        /** 
		 * 	generate file name
		 * 	@param 	{Object} extension 	- file extension (csv, pdf)
		 * 	@return {String} 			- file name
		 */ 
        generate_file_name(extension) {
			return `timestamps-${this.get_current_date()}.${extension}`
		}
	},
	computed : {
		...mapGetters(['employees', 'is_admin']),
		filtered_employees() {
			return (this.is_admin && this.area !== 'Todas')
			? this.employees.filter((t) => t.area === this.area)
			: this.employees
		},
    },
}
</script>
