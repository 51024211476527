<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" @submit.prevent="submit">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ action }} empleado</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Detalles de empleado</v-card-subtitle>
                <v-card-text>
                    <v-container fluid class="px-0">
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="first_name" label="Nombre" :rules="rules" required class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="last_name" label="Apellido" :rules="rules" required class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-select v-model="area" :items="areas" label="Area" :rules="rules" class="rounded-lg" outlined dense></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="employee_number" label="No. de Empleado" :rules="rules" required class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field v-model="card_id" label="Id tarjeta" :rules="rules" required class="rounded-lg" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="active" label="Activo" dense hide-details ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                    <v-btn type="submit" color="blue darken-1" text>Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EmployeeDetails',
	data: () => ({
		dialog: false,
        create: false,
        loading: false,

        first_name: '',
        last_name: '',
        area: '',
        employee_number: '',
        current_employee_number: '',
        card_id: '',
        active: true,

        rules: [ v => !!v || 'Campo es requerido' ],
	}),
	mounted() {
        this.$root.$on('employee-details', ({ create, employee }) => {
            this.create = create

            if (create) {
                this.area = this.areas[0]
            } else {
                let name = employee.name.split(', ')
                this.first_name = name[1]
                this.last_name = name[0]
                this.area = employee.area
                this.current_employee_number = employee.employee_number 
                this.employee_number = employee.employee_number 
                this.card_id = employee.card_id
                this.active = employee.active
            }
			
            this.dialog = true
        })
	},
	methods: {
        ...mapActions(['upsert_employee']),
        close() {
            this.dialog = false
            this.first_name = ''
            this.last_name = ''
            this.current_employee_number = ''
            this.employee_number = ''
            this.card_id = ''
            this.active = true

			this.$refs.form.resetValidation()
        },
        submit() {
            if (!this.$refs.form.validate()) return
            this.loading = true

            this.upsert_employee(this.employee).then(() => {
                this.close() 
            }).finally(() => {
                this.loading = false
            })
        }
	},
    computed: {
		...mapGetters(['areas', 'get_employee']),
        action() { return this.create ? 'Crear' : 'Editar' },
        employee() {
            return {            
                name: `${this.last_name}, ${this.first_name}`,
                area: this.area,
                employee_number: this.employee_number,
                current_employee_number: this.current_employee_number,
                card_id: this.card_id,
                active: this.active,
                create: this.create
            }
        }
	},
};
</script>